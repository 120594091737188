import React from "react";
import { messengerFlagLoader } from "gx-npm-lib";
import { FeatureFlagProvider } from "gx-npm-ui";
import App from "./app.component";
import { AppContextProvider } from "./app.context";
import { createGenerateClassName, StylesProvider } from "@material-ui/core/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppPreview from "./app-preview.component";

const generateClassName = createGenerateClassName({
  seed: "swo",
});

const Root = () => {
  return (
    <FeatureFlagProvider loader={messengerFlagLoader()}>
      <StylesProvider generateClassName={generateClassName}>
        <AppContextProvider>
          <BrowserRouter>
            <Routes>
              <Route path={"/s/questionnaire/:initiativeId/product/:initProductId/overview"} element={<App />} />
              <Route
                path={"/s/evaluation/:initiativeId/questionnaire/preview/overview"}
                element={<AppPreview isSetupMode={false} />}
              />
              <Route
                path={"/s/evaluation/:initiativeId/questionnaire/setup/preview/overview"}
                element={<AppPreview isSetupMode={true} />}
              />
              <Route
                path={"/s/evaluation/:initiativeId/questionnaire/setup/:surveyId/preview/overview"}
                element={<AppPreview isSetupMode={true} />}
              />
            </Routes>
          </BrowserRouter>
        </AppContextProvider>
      </StylesProvider>
    </FeatureFlagProvider>
  );
};

export default Root;
