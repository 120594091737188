import { colorPalette } from "gx-npm-common-styles";

const sidebarContainerWidth = 375;
const headerHeight = 212;
const footerHeight = 128;
const previewAdjustment = 48;
const legalBannerHeightAndMarginTop = 119;
const surveyWorkspaceOverviewStyles = {
  container: {
    display: "flex",
    flexDirection: "column" as const,
  },
  overviewRoot: {
    flexGrow: 1,
    overflowY: "auto" as const,
    background: colorPalette.neutrals.quartz.hex,
    minHeight: `calc(100vh - ${footerHeight + headerHeight + legalBannerHeightAndMarginTop}px)`,
    paddingLeft: "56px",
    paddingRight: "56px",
    paddingTop: "48px",
    transition: "all 0.5s",
    maxWidth: 1440,
    margin: "0 auto",
    width: "100%",
    "@media (max-width:1024px)": {
      paddingLeft: "24px",
      paddingRight: "24px",
    },
  },
  overviewRootForPreview: {
    minHeight: `calc(100vh - ${footerHeight + headerHeight + previewAdjustment}px)`,
  },
  cardAndSidebarContainer: {
    alignItems: "flex-start",
    display: "flex",
    margin: "0 auto",
  },
  scorecardContainer: {
    display: "inline-block",
    marginRight: "24px",
    maxWidth: "929px",
    verticalAlign: "top",
    width: "100%",
  },
  sidebarContainer: {
    display: "inline-block",
    minWidth: sidebarContainerWidth,
    overflowY: "auto" as const,
  },
};

export default surveyWorkspaceOverviewStyles;
