// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GWD2tBuSAdWCdqjUPsZP .p5RCvlqRWWslIPMIn818{border-radius:5px;height:12px;width:86px}.GWD2tBuSAdWCdqjUPsZP .UFdpyhhmeUxiEGY51N_M{background-color:var(--color-neutrals-silver)}.GWD2tBuSAdWCdqjUPsZP ._IxPsiZ61p1JdmkHX5Ec{background:var(--color-neutrals-iron) linear-gradient(to right, var(--color-accent-sour-kiwi) 25%, var(--color-accent-kiwi) 75%);border-radius:5px 0 0 5px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/linear-progress-bar/linear-progress-bar.styles.module.scss"],"names":[],"mappings":"AACE,4CACE,iBAAA,CACA,WAAA,CACA,UAAA,CAEF,4CACE,6CAAA,CAEF,4CACE,gIAAA,CACA,yBAAA","sourcesContent":[".root {\n  & .linearProgressRoot {\n    border-radius: 5px;\n    height: 12px;\n    width: 86px;\n  }\n  & .linearProgressColorPrimary {\n    background-color: var(--color-neutrals-silver)\n  }\n  & .linearProgressBar {\n    background: var(--color-neutrals-iron) linear-gradient(to right, var(--color-accent-sour-kiwi) 25%, var(--color-accent-kiwi) 75%);\n    border-radius: 5px 0 0 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `GWD2tBuSAdWCdqjUPsZP`,
	"linearProgressRoot": `p5RCvlqRWWslIPMIn818`,
	"linearProgressColorPrimary": `UFdpyhhmeUxiEGY51N_M`,
	"linearProgressBar": `_IxPsiZ61p1JdmkHX5Ec`
};
export default ___CSS_LOADER_EXPORT___;
