export enum ClientEvents {
  SURVEY_OVERVIEW_QUICKSTART_MODAL_OPENED = "SURVEY_OVERVIEW_QUICKSTART_MODAL_OPENED",
  SURVEY_OVERVIEW_QUICKSTART_OVERVIEW_CLICKED = "SURVEY_OVERVIEW_QUICKSTART_OVERVIEW_CLICKED",
  SURVEY_OVERVIEW_QUICKSTART_SHARING_RESPONSE_CLICKED = "SURVEY_OVERVIEW_QUICKSTART_SHARING_RESPONSE_CLICKED",
  SURVEY_OVERVIEW_QUICKSTART_DATA_PRIVACY_CLICKED = "SURVEY_OVERVIEW_QUICKSTART_DATA_PRIVACY_CLICKED",
  SURVEY_OVERVIEW_QUICKSTART_MODAL_CLOSED = "SURVEY_OVERVIEW_QUICKSTART_MODAL_CLOSED",
  SURVEY_PREVIEW_OVERVIEW_QUICKSTART_MODAL_OPENED = "SURVEY_PREVIEW_OVERVIEW_QUICKSTART_MODAL_OPENED",
  SURVEY_PREVIEW_OVERVIEW_QUICKSTART_OVERVIEW_CLICKED = "SURVEY_PREVIEW_OVERVIEW_QUICKSTART_OVERVIEW_CLICKED",
  SURVEY_PREVIEW_OVERVIEW_QUICKSTART_SHARING_RESPONSE_CLICKED = "SURVEY_PREVIEW_OVERVIEW_QUICKSTART_SHARING_RESPONSE_CLICKED",
  SURVEY_PREVIEW_OVERVIEW_QUICKSTART_DATA_PRIVACY_CLICKED = "SURVEY_PREVIEW_OVERVIEW_QUICKSTART_DATA_PRIVACY_CLICKED",
  SURVEY_PREVIEW_OVERVIEW_QUICKSTART_MODAL_CLOSED = "SURVEY_PREVIEW_OVERVIEW_QUICKSTART_MODAL_CLOSED",
}
