// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AZMeIYBSBBDJqhUsHw4x .gx-dialog-body{margin-left:-36px;width:600px}.dpWH42IwADWYUFhqQXdB{padding:24px 36px 56px 36px}.vxSPrpJvC5rNE4yszabw{background:var(--color-interactions-light-berry);padding:56px 0 74px 36px}.vxSPrpJvC5rNE4yszabw .oMFq3a2MZ9wlpKJkR1Mg{display:block}.vxSPrpJvC5rNE4yszabw .MXh8txkeO1hWuxyQyShd{position:relative}.vxSPrpJvC5rNE4yszabw .MXh8txkeO1hWuxyQyShd .ndq0xm1PqGBQDg3JXJ3Q{bottom:-74px;position:absolute;right:0}.JrmOkhbXIEnib2X5b7Wb{align-items:center;display:flex;justify-content:flex-end}.JrmOkhbXIEnib2X5b7Wb .Fi9OPAr3cCv0Mp136J4Q{margin-right:24px}`, "",{"version":3,"sources":["webpack://./../gx-npm-ui/src/free-trial/free-trial-init-create-modal/free-trial-init-create-modal.styles.module.scss"],"names":[],"mappings":"AACE,sCACE,iBAAA,CACA,WAAA,CAIJ,sBACE,2BAAA,CAGF,sBACE,gDAAA,CACA,wBAAA,CAEA,4CACE,aAAA,CAEF,4CACE,iBAAA,CAEA,kEACE,YAAA,CACA,iBAAA,CACA,OAAA,CAKN,sBACE,kBAAA,CACA,YAAA,CACA,wBAAA,CAEA,4CACE,iBAAA","sourcesContent":[".dialogRoot {\n  & :global(.gx-dialog-body) {\n    margin-left: -36px;\n    width: 600px;\n  }\n}\n\n.subtitleParagraph {\n  padding: 24px 36px 56px 36px\n}\n\n.remainingInitsHeading {\n  background: var(--color-interactions-light-berry);\n  padding: 56px 0 74px 36px;\n\n  & .headingSecondLine {\n    display: block;\n  }\n  & .cornerImgContainer {\n    position: relative;\n\n    & .cornerImg {\n      bottom: -74px;\n      position: absolute;\n      right: 0;\n    }\n  }\n}\n\n.footerButtonContainer {\n  align-items: center;\n  display: flex;\n  justify-content: flex-end;\n\n  & .cancelButton {\n    margin-right: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogRoot": `AZMeIYBSBBDJqhUsHw4x`,
	"subtitleParagraph": `dpWH42IwADWYUFhqQXdB`,
	"remainingInitsHeading": `vxSPrpJvC5rNE4yszabw`,
	"headingSecondLine": `oMFq3a2MZ9wlpKJkR1Mg`,
	"cornerImgContainer": `MXh8txkeO1hWuxyQyShd`,
	"cornerImg": `ndq0xm1PqGBQDg3JXJ3Q`,
	"footerButtonContainer": `JrmOkhbXIEnib2X5b7Wb`,
	"cancelButton": `Fi9OPAr3cCv0Mp136J4Q`
};
export default ___CSS_LOADER_EXPORT___;
