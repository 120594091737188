import { TextLink, TypographyComponent } from "gx-npm-ui";
import { InfoLightIcon } from "gx-npm-icons";
import { useTranslation } from "react-i18next";
import styles from "./legal.banner.styles.module.scss";
import { Fragment } from "react";

const LegalBannerComponent = () => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className={styles.container}>
        <div className={styles.legalBannerWrapper}>
          <div className={styles.legalBanner}>
            <InfoLightIcon />
            <div>
              <div className={styles.legalText}>
                <TypographyComponent boldness="regular" type="p3" color="coal">
                  {t(
                    "Vendor questionnaire responses will only be visible to the requester. Gartner may use responses for validation, research, and product development."
                  )}
                </TypographyComponent>
                <TextLink
                  rootClassName={styles.learnMoreLink}
                  href="https://intercom.help/gartner-buysmart-help-center/en/articles/7211528-buysmart-data-privacy-security-policy"
                  text={t("Learn more")}
                  target="_blank"
                  variant="tertiary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.divider} />
    </Fragment>
  );
};

export default LegalBannerComponent;
