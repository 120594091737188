import React, { Fragment, useContext } from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import surveyProgressInformation from "./survey-progress-information.styles";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import ProgressBar from "../progress-bar/progress-bar.component";
import {
  QuickStartPopperComponent,
  TextLink,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  TypographyComponent,
} from "gx-npm-ui";
import { AppContext } from "../../app.context";
import OverviewComponent from "./quick-start/overview/overview.component";
import DataPrivacyComponent from "./quick-start/data-privacy/data-privacy.component";
import SharingResponsesComponent from "./quick-start/sharing-responses/sharing-responses.component";
import { useCaptureEventsV2 } from "gx-npm-lib";
import { ClientEvents } from "../../app.constants";
import { GCOM_3606__fontUpdate } from "../../lib/feature-flag";

const useStyles = makeStyles(surveyProgressInformation);
const SurveyProgressInformation = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const captureEvents = useCaptureEventsV2();
  const {
    isPreviewMode,
    isOnboardingDrawerOpen,
    initId,
    initProductId,
    docsUploaded,
    docsTotal,
    questionsTotal,
    questionsCompleted,
    isLoading,
    setIsOnboardingDrawerOpen,
  } = useContext(AppContext);
  const quickStartTabsData = [
    { label: "Overview", content: <OverviewComponent /> },
    { label: "Sharing Responses", content: <SharingResponsesComponent /> },
    { label: "Data Privacy", content: <DataPrivacyComponent /> },
  ];

  const metaData = {
    initiativeId: initId,
    initProdId: initProductId,
  };

  const clientEvents = [
    ClientEvents.SURVEY_OVERVIEW_QUICKSTART_OVERVIEW_CLICKED,
    ClientEvents.SURVEY_OVERVIEW_QUICKSTART_SHARING_RESPONSE_CLICKED,
    ClientEvents.SURVEY_OVERVIEW_QUICKSTART_DATA_PRIVACY_CLICKED,
  ];

  const clientEventsForPreview = [
    ClientEvents.SURVEY_PREVIEW_OVERVIEW_QUICKSTART_OVERVIEW_CLICKED,
    ClientEvents.SURVEY_PREVIEW_OVERVIEW_QUICKSTART_SHARING_RESPONSE_CLICKED,
    ClientEvents.SURVEY_PREVIEW_OVERVIEW_QUICKSTART_DATA_PRIVACY_CLICKED,
  ];

  const handleEventCapture = (tabIndex: number) => {
    if (isPreviewMode) {
      captureEvents([
        {
          eventType: clientEventsForPreview[tabIndex],
          metaData: { initiativeId: initId },
        },
      ]);
    } else {
      captureEvents([
        {
          eventType: clientEvents[tabIndex],
          metaData,
        },
      ]);
    }
  };

  return (
    <Fragment>
      {!isLoading && (
        <div className={classNames(classes.scoreContainer)}>
          <div>
            <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
              <FeatureFlagBooleanOn>
                <TypographyComponent styling={"p4"} color={"white"} boldness={"regular"}>
                  {t("Questionnaire questions answered")}
                </TypographyComponent>
              </FeatureFlagBooleanOn>
              <FeatureFlagBooleanOff>
                <p className="p4">{t("Questionnaire questions answered")}</p>
              </FeatureFlagBooleanOff>
            </FeatureFlagBooleanContainer>

            <Box className={classes.scoreProgressWrapper}>
              <Box>
                {
                  <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                    <FeatureFlagBooleanOn>
                      <TypographyComponent styling={"h5"} color={"white"} boldness={"medium"}>
                        {questionsCompleted} / {questionsTotal}
                      </TypographyComponent>
                    </FeatureFlagBooleanOn>
                    <FeatureFlagBooleanOff>
                      <h5 className={"gx-questions-complete-percentage"}>
                        {questionsCompleted} / {questionsTotal}
                      </h5>
                    </FeatureFlagBooleanOff>
                  </FeatureFlagBooleanContainer>
                }
              </Box>
              <Box className={classes.scoreProgressBar}>
                <ProgressBar disabled={false} percent={(questionsCompleted / questionsTotal) * 100} />
              </Box>
            </Box>
            {docsTotal > 0 && (
              <Fragment>
                <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                  <FeatureFlagBooleanOn>
                    <TypographyComponent styling={"p4"} color={"white"} boldness={"regular"}>
                      {t("Documents uploaded")}
                    </TypographyComponent>
                  </FeatureFlagBooleanOn>
                  <FeatureFlagBooleanOff>
                    <p className="p4">{t("Documents uploaded")}</p>
                  </FeatureFlagBooleanOff>
                </FeatureFlagBooleanContainer>

                <Box className={classes.scoreProgressWrapper}>
                  <Box>
                    <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
                      <FeatureFlagBooleanOn>
                        <TypographyComponent styling={"h5"} color={"white"} boldness={"medium"}>
                          {docsUploaded} / {docsTotal}
                        </TypographyComponent>
                      </FeatureFlagBooleanOn>
                      <FeatureFlagBooleanOff>
                        <h5 className={"gx-documents-uploaded-percentage"}>
                          {docsUploaded} / {docsTotal}
                        </h5>
                      </FeatureFlagBooleanOff>
                    </FeatureFlagBooleanContainer>
                  </Box>
                  <Box className={classes.scoreProgressBar}>
                    <ProgressBar disabled={false} percent={(docsUploaded / docsTotal) * 100} />
                  </Box>
                </Box>
              </Fragment>
            )}
          </div>
          <TextLink
            onClick={() => {
              if (isPreviewMode) {
                captureEvents([
                  {
                    eventType: ClientEvents.SURVEY_PREVIEW_OVERVIEW_QUICKSTART_MODAL_OPENED,
                    metaData: { initiativeId: initId },
                  },
                ]);
              } else {
                captureEvents([
                  {
                    eventType: ClientEvents.SURVEY_OVERVIEW_QUICKSTART_MODAL_OPENED,
                    metaData,
                  },
                ]);
              }
              setIsOnboardingDrawerOpen(true);
            }}
            rootClassName={classNames("gx-buysmart-survey-link")}
            text={t("What is a BuySmart Questionnaire?")}
            variant="secondary"
          />
        </div>
      )}

      <QuickStartPopperComponent
        title={t("Vendor questionnaire experience")}
        isOpen={isOnboardingDrawerOpen}
        onClose={() => {
          setIsOnboardingDrawerOpen(false);
          if (isPreviewMode) {
            captureEvents([
              {
                eventType: ClientEvents.SURVEY_PREVIEW_OVERVIEW_QUICKSTART_MODAL_CLOSED,
                metaData: { initiativeId: initId },
              },
            ]);
          } else {
            captureEvents([
              {
                eventType: ClientEvents.SURVEY_OVERVIEW_QUICKSTART_MODAL_CLOSED,
                metaData,
              },
            ]);
          }
        }}
        tabsData={quickStartTabsData}
        onTabClick={handleEventCapture}
      />
    </Fragment>
  );
};

export default SurveyProgressInformation;
