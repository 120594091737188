import { colorPalette } from "gx-npm-common-styles";

const colorHuckleberry = colorPalette.accent.huckleberry.hex;
const colorSweetHuckleberry = colorPalette.accent.sweetHuckleberry.hex;

const surveyProgressInformation = {
  scoreContainer: {
    background: `linear-gradient(to bottom, ${colorHuckleberry} 75%, ${colorSweetHuckleberry} 25%)`,
    borderRadius: "8px",
    height: "260px",
    paddingTop: "32px",
    paddingLeft: "40px",
    paddingRight: "40px",
    position: "relative" as const,
    "& .gx-buysmart-survey-link": {
      bottom: "20px",
      position: "absolute" as const,
      "& p": {
        color: colorPalette.basic.white.hex,
      },
      "&:hover, &:focus": {
        background: colorPalette.neutrals.silver.hex,
        "& p": {
          color: colorPalette.neutrals.carbon.hex,
        },
        "& path": {
          fill: colorPalette.neutrals.carbon.hex,
        },
      },
    },
    "& path": {
      fill: colorPalette.neutrals.silver.hex,
    },
  },
  scoreProgressWrapper: {
    alignItems: "center",
    display: "flex",
    marginTop: "8px",
    marginBottom: "20px",
  },
  scoreProgressBar: {
    flexGrow: 1,
    paddingLeft: "17px",
  },
};

export default surveyProgressInformation;
