import React, { useEffect, useRef, useState } from "react";
import {
  Loader,
  Paragraph,
  TextLink,
  TypographyComponent,
  FeatureFlagBooleanContainer,
  FeatureFlagBooleanOff,
  FeatureFlagBooleanOn,
  useFeatureFlag,
} from "gx-npm-ui";
import styles from "./overview.styles.module.scss";
import * as videoPlayer from "@vidyard/embed-code";
import { useTranslation } from "react-i18next";
import { TimerIcon } from "gx-npm-icons";
import classNames from "classnames";
import { GCOM_3606__fontUpdate } from "../../../../lib/feature-flag";

const OverviewComponent = () => {
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const isFontUpdateFFOn = useFeatureFlag(GCOM_3606__fontUpdate);

  useEffect(() => {
    (async () => {
      try {
        const player = await videoPlayer.api.renderPlayer({
          uuid: "7ipTRdHCn3RXhoqUka2zSA",
          container: videoRef.current,
        });
        player.on("ready", () => {
          setIsLoading(false);
        });
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, [videoRef]);

  return (
    <div>
      <TypographyComponent type={"h5"} boldness={isFontUpdateFFOn ? "medium" : "semi"}>
        {t("Overview")}
      </TypographyComponent>
      <div className={styles.mediaContainer}>
        <div ref={videoRef} className={styles.media}>
          {isLoading && (
            <div aria-label={"loader"} className={classNames(styles.loader)}>
              <Loader size={48} />
            </div>
          )}
        </div>
        <div className={styles.mediaDesc}>
          <TypographyComponent type={"p3"} boldness={"medium"} rootClassName={styles.mediaContent}>
            {t("Watch a quick overview of how vendor questionnaires work in BuySmart")}
          </TypographyComponent>
          <FeatureFlagBooleanContainer flagName={GCOM_3606__fontUpdate}>
            <FeatureFlagBooleanOn>
              <TypographyComponent
                rootClassName={styles.mediaTimeGCOM3606}
                styling={"p3"}
                color={"iron"}
                boldness={"regular"}
              >
                <TimerIcon className={styles.timerIcon} />
                1:02
              </TypographyComponent>
            </FeatureFlagBooleanOn>
            <FeatureFlagBooleanOff>
              <Paragraph rootClassName={styles.mediaTime} type={"p3"} boldness={"regular"}>
                <TimerIcon className={styles.timerIcon} />
                1:02
              </Paragraph>
            </FeatureFlagBooleanOff>
          </FeatureFlagBooleanContainer>
        </div>
      </div>
      <div className={styles.description}>
        <TypographyComponent rootClassName={styles.heading} type={"p2"} boldness={isFontUpdateFFOn ? "medium" : "semi"}>
          {t("What is a vendor questionnaire?")}
        </TypographyComponent>
        <TypographyComponent rootClassName={styles.additionalInfo} type={"p2"} boldness={"regular"}>
          {t(
            "When you receive a questionnaire from a team using Gartner BuySmart, you and/or your team have an opportunity to provide detailed information about your product to the buying team. The information you provide will guide their assessment of the products that they are considering for purchase."
          )}
        </TypographyComponent>
        <div className={styles.helpLink}>
          <TextLink
            text={t("Read more about questionnaires")}
            target={"_blank"}
            href={
              "https://intercom.help/gartner-buysmart-help-center/en/articles/8283442-intro-to-buysmart-questionnaires-for-recipients"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default OverviewComponent;
